import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://saengcaday.pockethost.io');

const FilterHeader = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const artist = queryParams.get('artist');
  const startDate = queryParams.get('startdate') || '시작 날짜 무관';
  const endDate = queryParams.get('enddate') || '종료 날짜 무관';
  const [artistNames, setArtistNames] = useState([]);
  const [artistCount, setArtistCount] = useState(0);

  useEffect(() => {
    if (artist) {
      const artist_list = artist.split(',');
      const artist_query = artist_list.map(a => `id = "${a}"`);
      pb.collection('celebrities')
        .getFullList({
          filter: artist_query.join(' || '),
        })
        .then(res => {
          setArtistNames(res.map(artist => artist.name));
          setArtistCount(res.length);
        });
    } else {
      setArtistNames(['생일자 무관']);
      setArtistCount(0);
    }
  }, [artist]);

  return (
    <a href="/filter">
      <section className="w-full flex justify-center items-center p-1 rounded-md lg:h-[92px] tb:h-[64px] sm:h-[60px] 2sm:h-[52px] xs:h-[44px] 2xs:h-[36px] bg-white">
        <ul className="w-full text-gray-600 flex flex-row list-none justify-center items-center 2xs:gap-2 gap-4 tb:gap-10 lg:gap-14">
          {queryParams.size === 0 ? (
            <p className="2xs:text-[10px] xs:text-[12px] 2sm:text-[14px] sm:text-[16px] tb:text-[18px] lg:text-[20px] font-medium">
              생일자와 날짜를 선택하세요
            </p>
          ) : (
            <>
              <li className="flex flex-row gap-1 tb:gap-2">
                {artistNames.slice(0, 2).map((name, id) => (
                  <span
                    key={id}
                    className="2xs:text-[10px] xs:text-[12px] 2sm:text-[13.6px] sm:text-[14px] tb:text-[16px] font-bold"
                  >
                    {name}
                  </span>
                ))}
                {artistCount > 2 && (
                  <span className="2xs:text-[9.2px] xs:text-[11.2px] 2sm:text-[12.8px] sm:text-[14px] tb:text-[14.8px] font-normal">
                    외 {artistCount - 2}인
                  </span>
                )}
              </li>
              <li className="2xs:text-[10px] xs:text-[12px] 2sm:text-[13.6px] sm:text-[14px] tb:text-[16px] font-bold">
                {startDate} ~ {endDate}
              </li>
            </>
          )}
        </ul>
      </section>
    </a>
  );
};

export default FilterHeader;
