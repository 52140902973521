import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import './index.css';

import PocketBase from 'pocketbase';

const pb = new PocketBase('https://saengcaday.pockethost.io');

const formatDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}.${month}.${day}`;
};

const today = new Date();
const oneWeekAgo = new Date(today);
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

const formattedOneWeekAgo = `${(oneWeekAgo.getMonth() + 1).toString().padStart(2, '0')}-${oneWeekAgo.getDate().toString().padStart(2, '0')}`;

const Filter = () => {
  const [artists, setArtists] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split('T')[0],
  );
  const [endDate, setEndDate] = useState('2024-09-30');

  const handleSaveFilter = () => {
    const artistQuery = selectedArtists.join(',');
    const queryString = `?artist=${artistQuery}&startdate=${encodeURIComponent(startDate)}&enddate=${encodeURIComponent(endDate)}`;
    window.location.href = '/' + queryString;
  };

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const artists = await pb.collection('celebrities').getFullList();

        const filteredArtists = artists.filter(artist => {
          const birthMonthDay = artist.birth.slice(5);
          return birthMonthDay >= formattedOneWeekAgo;
        });
        const sortedArtists = filteredArtists.sort((a, b) => {
          const aBirthday = new Date(a.birth).toISOString().slice(5, 10);
          const bBirthday = new Date(b.birth).toISOString().slice(5, 10);
          return aBirthday.localeCompare(bBirthday);
        });
        setArtists(sortedArtists);
      } catch (error) {
        console.error('Error fetching artists:', error);
      }
    };

    fetchArtists();
  }, []);

  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain">
      <Header title="검색하기" />
      <div className="w-full h-auto relative flex flex-col 2xs:px-8 xs:px-10 2sm:px-7 sm:px-12 tb:px-24 lg:px-32 xl:px-48 2xs:pt-20 xs:pt-24 pt-28 tb:pt-28 lg:pt-36 pb-8 items-center justify-center">
        <ul className="w-full flex flex-col list-none justify-center items-center 2xs:gap-8 xs:gap-10 gap-12 sm:gap-20 tb:gap-24 lg:gap-32">
          <li className="w-full font-bold 2xs:text-sm tb:text-xl lg:text-2xl">
            이름
            <ul className="flex flex-wrap flex-row 2xs:gap-3 xs:gap-3 2sm:gap-3 gap-4 tb:gap-5 lg:gap-6 mt-3">
              {artists.map(artist => (
                <li key={artist.id} className="text-center">
                  <p
                    className={`select-group ${selectedArtists.includes(artist.id) && 'selected'} 2xs:text-[8px] xs:text-[8.8px] 2sm:text-[10px] sm:text-[11.2px] tb:text-[15px] lg:text-[16px] font-light text-gray-700`}
                  >
                    {artist.group}
                  </p>
                  <p
                    key={artist.id}
                    className={`select-btn ${selectedArtists.includes(artist.id) && 'selected'} 2xs:text-[10px] xs:text-[12px] 2sm:text-[14px] sm:text-[14px] tb:text-[18px] lg:text-[22px] font-normal`}
                    onClick={() => {
                      if (selectedArtists.includes(artist.id)) {
                        setSelectedArtists(
                          selectedArtists.filter(
                            selectedArtist => selectedArtist !== artist.id,
                          ),
                        );
                      } else {
                        setSelectedArtists([...selectedArtists, artist.id]);
                      }
                    }}
                  >
                    {artist.name}
                  </p>
                  <p
                    className={`select-group ${selectedArtists.includes(artist.id) && 'selected'} 2xs:text-[8px] xs:text-[8.8px] 2sm:text-[10px] sm:text-[11.2px] tb:text-[15px] lg:text-[16px] font-light text-gray-500`}
                  >
                    🎂 {formatDate(artist.birth)}
                  </p>
                </li>
              ))}
            </ul>
          </li>
          <li
            className="w-full font-bold 2xs:text-sm tb:text-xl lg:text-2xl"
            id="date"
          >
            날짜
            <p className="mt-3 flex flex-row 2xs:gap-2 xs:gap-3 sm:gap-8 tb:gap-20 lg:gap-24 gap-5">
              <input
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                min={new Date().toISOString().split('T')[0]}
              />
              <p className="2xs:text-xs tb:text-lg">~</p>

              <input
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                min={new Date().toISOString().split('T')[0]}
              />
            </p>
          </li>
        </ul>
        <div className="w-full flex flex-col items-center justify-center">
          <button
            onClick={handleSaveFilter}
            className="w-full bg-[#454545] mt-12 sm:mt-24 tb:mt-28 lg:mt-44 2xs:py-3 py-4 tb:py-5 lg:py-6 2xs:text-[12px] xs:text-[14px] 2sm:text-[16px] sm:text-[16px] tb:text-[18px] lg:text-[24px] text-white rounded-2xl font-MainFont"
          >
            생카 검색하기 🔍
          </button>

          <a
            href="https://forms.gle/xhKKXhi6LwSv11ki7"
            className="mt-3 tb:mt-4 lg:mt-6 text-blue-600 underline font-MainFont 2xs:text-[10px] xs:text-[12px] 2sm:text-[14px] sm:text-[14px] tb:text-[16px] lg:text-[22px]"
            rel="noreferrer"
            target="_blank"
          >
            없는 생카 제보하기
          </a>
        </div>
      </div>
    </div>
  );
};

export default Filter;
