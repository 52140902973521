import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import CafeCard from '../../components/CafeCard/CafeCard';
import PocketBase from 'pocketbase';
import './index.css';
import { getGumletUrl } from '../../lib/optimizer';

const pb = new PocketBase('https://saengcaday.pockethost.io');

const formatDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}.${month}.${day}`;
};

const today = new Date();
const oneWeekAgo = new Date(today);
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

const formattedOneWeekAgo = `${(oneWeekAgo.getMonth() + 1).toString().padStart(2, '0')}-${oneWeekAgo.getDate().toString().padStart(2, '0')}`;

const Total = () => {
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const artists = await pb.collection('celebrities').getFullList();

        const filteredArtists = artists.filter(artist => {
          const birthMonthDay = artist.birth.slice(5);
          return birthMonthDay >= formattedOneWeekAgo;
        });
        const sortedArtists = filteredArtists.sort((a, b) => {
          const aBirthday = new Date(a.birth).toISOString().slice(5, 10);
          const bBirthday = new Date(b.birth).toISOString().slice(5, 10);
          return aBirthday.localeCompare(bBirthday);
        });
        setArtists(sortedArtists);

        if (sortedArtists.length > 0) {
          setSelectedArtist(sortedArtists[0].id);
        }
      } catch (error) {
        console.error('Error fetching artists:', error);
      }
    };
    fetchArtists();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await pb.collection('events').getFullList({
          sort: 'start_date',
          expand: 'cafe',
        });
        setEvents(events);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const filteredEvents = events
    .filter(event => event.celebrities.includes(selectedArtist))
    .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  const currentDate = new Date().toISOString().split('T')[0];

  const pastEvents = filteredEvents.filter(
    event => new Date(event.end_date) < new Date(currentDate),
  );

  const futureEvents = filteredEvents.filter(
    event => new Date(event.end_date) >= new Date(currentDate),
  );

  return (
    <div className="w-full min-h-screen bg-NoteBg bg-repeat-y bg-contain">
      <Header title="전체 생일카페 리스트" />
      <div className="w-full flex flex-col gap-8 tb:gap-10 lg:gap-10 p-8 items-center justify-center">
        <div className="scrollbar w-full h-auto overflow-x-auto 2xs:mt-2 2xs:mb-2 xs:mt-8 xs:mb-4 2sm:mt-8 2sm:mb-6 sm:mt-9 sm:mb-16 tb:mt-10 tb:mb-16 lg:mt-16 lg:mb-10 tb:px-4 lg:px-20 xl:px-28">
          <div className="scrollbar w-[360px] sm:w-[520px] tb:w-[600px] lg:w-[800px] xl:w-[1200px] flex over-x-scroll font-bold 2xs:text-sm tb:text-xl lg:text-2xl">
            <ul className="flex flex-row 2xs:gap-3 xs:gap-4 gap-4 sm:gap-4 tb:gap-10 lg:gap-16 xl:gap-12 px-1 py-4 ">
              {artists.map(artist => (
                <li
                  key={artist.id}
                  className="text-center items-center flex flex-col gap-2 lg:gap-4"
                  onClick={() => {
                    setSelectedArtist(artist.id);
                  }}
                >
                  <span
                    className={`select-group ${selectedArtist === artist.id && 'selected'}  2xs:text-[8px] xs:text-[8.8px] 2sm:text-[10px] sm:text-[11.2px] tb:text-[15px] lg:text-[16px] font-light text-gray-700`}
                  >
                    {artist.group}
                  </span>
                  <p>
                    <img
                      src={getGumletUrl(artist, artist.profile, {
                        w: 200,
                        h: 200,
                        mode: 'crop',
                        crop: 'faces',
                      })}
                      className={`select-profile ${selectedArtist === artist.id && 'selected'} 2xs:w-10 2xs:h-10 xs:w-12 xs:h-12 2sm:w-14 2sm:h-14 sm:w-16 sm:h-16 tb:w-24 tb:h-24 lg:w-24 lg:h-24 rounded-full`}
                      alt=""
                    />
                  </p>
                  <p>
                    <p
                      key={artist.id}
                      className={`select-btn ${selectedArtist === artist.id && 'selected'} flex flex-col 2xs:text-[10px] xs:text-[12px] 2sm:text-[14px] sm:text-[14px] tb:text-[18px] lg:text-[22px] font-normal`}
                    >
                      {artist.name}
                    </p>

                    <p
                      className={`select-group ${selectedArtist === artist.id && 'selected'} mt-1 2xs:text-[7.2px] xs:text-[8px] 2sm:text-[10px] sm:text-[10px] tb:text-[12px] lg:text-[14px] font-light text-gray-500`}
                    >
                      🎂 {formatDate(artist.birth)}
                    </p>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-8">
          {loading && <p>업데이트 중입니다.</p>}
          {!loading && futureEvents.length === 0 && pastEvents.length === 0 && (
            <p className="2xs:text-[10px] xs:text-[12px] 2sm:text-[14px] sm:text-[14px] tb:text-[18px] lg:text-[22px] font-medium">
              데이터 업데이트 중입니다!
            </p>
          )}
          {futureEvents.map(event => (
            <Link to={`/info/${event.id}`} key={event.id}>
              <CafeCard
                name={event.expand.cafe.name}
                position={event.expand.cafe.position}
                startDate={formatDate(event.start_date)}
                endDate={formatDate(event.end_date)}
                time={event.open_hours.info}
                event={event}
                reserveType={event.reserve_type}
              />
            </Link>
          ))}
          {pastEvents.map(event => (
            <Link to={`/info/${event.id}`} key={event.id}>
              <CafeCard
                name={event.expand.cafe.name}
                position={event.expand.cafe.position}
                startDate={formatDate(event.start_date)}
                endDate={formatDate(event.end_date)}
                time={event.open_hours.info}
                event={event}
                reserveType={event.reserve_type}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Total;
